.DuelMetricBarBox {
    position: relative;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 330px;
    justify-content: space-evenly;
}


.Icon {
    position: absolute;
    top: 14px;
    left:15px;
    font-size: 30px;
}

.BarSections {
    /*background-color: lightgrey;*/
    flex-basis: 172px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 20px;
}

.BarSections > div:first-child{
    margin-bottom: 10px;
}


.BarSection {
    width: 100%;
    padding: 0 15px;
}


.BarWrapper {
    overflow: hidden;
}


.BarText {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 22px;
    font-weight: normal;
    color: #242424;
}

.Title {
    font-family: 'Roboto', sans-serif;
    font-size: 22px;
    font-weight: normal;
    color: #242424;
    flex: 1;
}

.StatsWrapper {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 18px;
    line-height: 1.2;
    color: #4F4F4F;
    text-align: center;
    flex: 1;
}

.StatsWrapper > div:first-child{
    margin-bottom: 7px;
}

.RechartsWrapper {

}