.Button {
    text-align: right;
    font-weight: bold;
    font-size: 15px;
    color: #53A0C6;
    padding: 0;
}

/*.Button:focus {*/
/*    color: #53A0C6;*/
/*    text-decoration: none;*/
/*}*/

.ButtonWrapper {
    text-align: right;
}

.TwoButtonWrapper {
    text-align: right;
}

