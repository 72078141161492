.SmallIconButton {
    border: solid 1px #838485;
    height: 33px;
    width: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: 20px;
    color: #838485;
    cursor: pointer;
}