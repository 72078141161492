.MainMenuItem {
    margin: 0;
    list-style: none;
    padding-left: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 20px;
}

.MainMenuItem li {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
}

.MainMenuItem li:hover {
    color: #c3f6f4;
}

.MainMenuItem svg {
    padding-right: 10px;
    font-size: 30px;
}

.Badge {
    background-color: #E13E6A;
    margin-left: 5px;
}