.CreateRoleScreen {
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
}

.RolebotImage {
    width: 120px;
    margin-bottom: 10px;
}

.Header {
    margin-bottom: 10px;
    font-size: 20px;
    font-family: 'Roboto';
    color: "#242424";
    font-weight: 500;
    text-align: center;
}

.Message {
    font-size: 15px;
    font-family: 'Helvetica';
    font-weight: 400;
    color: "#191D29";
    text-align: center;
}

/*Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
    .CreateRoleScreen {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}
