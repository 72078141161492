.AdminMustHaveFeedback {

}

.MustHaveTagWrapper {
    padding: 5px 0;
}

.HelpIcon {
    position: relative;
    top: 2px;
    color: #4BA7C3;
    margin-left: 10px;
}