.Label {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #242424;
}

.NewAdvanceSliderButton {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Icon {
    color: #3DAAE1;
    margin-left: 10px;
}