.DropDownWrapper {
    position: relative;
    margin-top: 3.9rem;
}

.AddedRowsDropDown {
    border: solid 2px #DFE1E1;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    padding: 5px 7px;
    max-height: 250px;
    overflow-y: auto;
    border-radius: 10px;
}

.NotFound {
    cursor: pointer;
    font-size: 16px;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: unset;
    margin-bottom: 8px;
    color: #242424;
    padding: 2px 10px;
}

.NotFound:hover {
    background-color: #EEEEEE;
    border-radius: 5px;
}

.NotFound:last-child {
    margin-bottom: 0;
}

.ActiveRole {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #838485;
    text-transform: uppercase;
    margin: 12px 0 10px 12px;
}