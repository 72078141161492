.OuterTag {
    position: relative;
    background-color: white;

    border-radius: 3px;
    margin-right: 5px;
    display: inline-flex;
    margin-bottom: 6px;
    user-select: none;
}

.InnerTag {
    display: flex;
    align-items: center;
    padding: 0 5px;
}

.closeSection {
    display: flex;
    justify-content: center;
    font-size: 12px;
    padding: 0;
    align-items: center;
    margin: 0;
    width: 15px;
    cursor: pointer;
    z-index: 1000;
    user-select: none;
}

.TagBlue {
    font-family: Helvetica, Arial, sans-serif;
    background: #Dcf1f8;
    color: #242424;
    border: solid #0A7AA2 1px;
}

.TagWhite {
    font-family: Helvetica, Arial, sans-serif;
    background: #FFFFFF;
    color: #242424;
    border: solid #B3B3B3 1px;
}

.CloseIcon {
    font-size: 15px;
    color: #242424;
}