.RoleHistorySection {

}

.Title {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 17px;
    color: #18234D;
    padding-bottom: 10px;
    padding-top: 10px;
}