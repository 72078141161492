.RoleHistoryItem {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 16px;
    color: #242424;
}



.DateColumn {
    text-align: center;
    color: #b3b3b3;
    /*display: flex;*/
    /*flex-direction: row;*/
    /*justify-content: center;*/
    /*align-items: center;*/
}

.ClockIcon {
    font-size: 18px;
    margin-right: 5px;
    position: relative;
    top: 4px;
}


.TitleColumn {
    padding-left: 20px !important;
    max-width: 150px;
}

.TitleIconWrapper {
    margin-right: 5px;
    min-width: 20px;
    text-align: center;
    display: inline-block;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.TitleColumnIcon {
    position: relative;
    top: 3px;
    color: #18234d;
}









