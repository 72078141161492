.SwitchInput {
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Message {
    margin-left: 10px;
    font-size: 20px;
}