
.Wrapper {
    position: relative;
}

.DropDown {
    background: #F5F5F5;
    border: solid black 1px;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 100;
    max-height: 150px;
    overflow-y: scroll;
}

.ApolloSequenceItem {
    padding: 5px;
    cursor: pointer;
}

.ApolloSequenceItem:hover {
    background-color: #e0e0e0;
}

.ApolloNoResultsItem {
    padding: .2rem;
    position: absolute;
    background: #F5F5F5;
    border: solid black 1px;
    left: 0;
    right: 0;
}