.EllipseButtonWrapper {
    position: relative;
    display: flex;
    justify-content: center;
}

.Open {

}

.EllipseButton {
    background-color: unset;
    height: 25px;
    width: 25px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    cursor: pointer;
}
.EllipseButton:hover {
    background-color: #eeeeee;
}

.MenuBox {
    opacity: 0;
    width: 0;
    transition: opacity ease-in 500ms, width 1ms linear 500ms;
    position: absolute;
    border-radius: 10px;
    transform:  translateX(-100%) translateY(-50%);
    background-color: #FFFFFF;
    top: 0;
    left: 0;
    padding: 0;
    margin-top: 12px;

    overflow: hidden;
}

.Open .MenuBox {
    opacity: 1;
    width: inherit;
    padding: 10px;
    border: solid 1px lightgray;
    transition: opacity 500ms ease-in, width 1ms linear 500ms;
    box-shadow: 0 0 4px 2px rgba(0,0,0,0.1);
}