.FormGroup {
    position: relative;
}

.SearchIcon {
    color: #737373;
    position: absolute;
    left:13px;
    top: 10px;
}

.CloseIcon {
    color: #737373;
    position: absolute;
    right:13px;
    top: 10px;
    cursor: pointer;
}

.SearchBox {
    border-radius: 20px;
    border-color: #B5B6B6;
    border-width: 2px;
    color: #737373;
    padding: 0 30px;
}