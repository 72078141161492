.CheckBox label {
    display: inline-flex;
    align-items: center;
    height: 25px;
}

.Text {
    line-height: normal;
    padding-top: 4px;
    padding-left: 7px;
    margin-right: 6px;
}