.ConfirmationModal{

}

.Header {
    padding-bottom: 0;
    border: none;
}

.Header Button {
    color: #c4c4c4;
    opacity: unset;
    font-size: 25px;
}

.Header Button:hover {
    color: #838485;
    opacity: unset;
}

.Title {
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    display: flex;
}

.Icon {
    color: #E43F3F;
    margin-right: 10px;
    font-size: 48px;
}

.Body {
    margin: 0 58px;
    font-size: 18px;
    font-family: Helvetica, Arial, sans-serif;
    color: #242424;
}

.Footer {
    border: none;
}

.DownLoadIcon {
    color: #18234d;
    margin-right: 10px;
    font-size: 48px;
}