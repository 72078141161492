.Button {
    width: 100%;
    font-family: "Circular-Book";
    background-color: #000000;
    border: none;
    color: #ffffff;
    outline: none;
    cursor: pointer;
    font-size: 15px;
    padding: 20px 30px;
    border-radius: 100px;
    text-align: center;
    width: 100%;
}

.Disabled {
    background-color: #9B9B9B;
    cursor: not-allowed;
}