.FilterTypePopUp {
    cursor: pointer;
}

.PopUpWrapper {
    position: relative;
}

.PopUp {
    position: absolute;
    top: 5px;
    border: solid 2px lightgray;
    background-color: #FFFFFF;
    min-width: 300px;
    padding: 10px;
    z-index: 100;
    border-radius: 10px;
}

.FilterListWrapper {
    border: solid 2px lightgray;
    padding: 4px;
    border-radius: 10px;
}

.ClearButton {
    margin-bottom: 4px;
}

.FilterButton {
    border: solid 2px lightgray;
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
    cursor: pointer;
    border-radius: 5px;
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
}

.FilterText {
    margin-right: 4px;
}

.FilterButton .DownArrow {

}

.Arrow {
    transition-duration: .4s;
}

.DownArrow {
    transform: rotate(-180deg);
}