.RoleRequestItem {
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 15px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    background-color: #FFFFFF;
}

.IconWrapper {
    min-height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 45px;
}

.UserInfoWrapper {
    min-height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ButtonToolBar {
    display: flex;
    min-height: 70px;
    align-items: center;
}

.Name {
    font-size: 22px;
    font-family: 'Roboto', sans-serif;
    color: #18234d;
}

.RoleName {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 18px;
    color: #4F4F4F;
}

.Date {
    font-size: 16px;
    font-family: Helvetica, Arial, sans-serif;
    color: #b3b3b3;
}

.Button {
    flex: 1;
}

.CreateIcon {
    color: #43D2C8;
}

.OpenIcon {
    color: #51B9DA;
}

.CloseIcon {
    color: #E13E6A;
}

.DeleteIcon {

}