.UserInfoWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 395px;
}

.Text {
    font-size: 31px;
    margin-top: 25px;
}