.Layout {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.IsAuth {
    background: #d4def1;
    background: -moz-linear-gradient(top, #d4def1 0%, #dce6f7 100%);
    background: -webkit-linear-gradient(top, #d4def1 0%,#dce6f7 100%);
    background: linear-gradient(to bottom, #d4def1 0%,#dce6f7 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d4def1', endColorstr='#dce6f7',GradientType=0 );
}

.Main {
    flex: 1;
    overflow: auto;

}


/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
    .IsAuth {
        background: #ffffff;
    }
}