.FilterTopSection {

}

.ClearAllButton {
    cursor: pointer;
    user-select: none;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: unset;
    color: #838485;
}

.ClearAllButton:hover {
    color: #4F4F4F;
}

.Title {
    font-family: 'Roboto', sans-serif;
    color: #242424;
    font-size: 20px;
    font-weight: 500;
}