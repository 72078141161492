.PopUp {
    position: absolute;
    top: 5px;
    border: solid 2px lightgray;
    min-width: 300px;
    background-color: #FFFFFF;
    padding: 10px;
    z-index: 100;
    border-radius: 10px;
}
