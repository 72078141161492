.ApolloRoleLinker {
    margin-bottom: 15px;
}




.ModalGroupName {
    color: red;
    font-weight: bold;
}

.ChevronIcon {
    position: absolute;
    right: 25px;
    bottom: 25px;
}

.InputOpen {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.InputLabel {
    font-family: 'Roboto', Arial, sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: #4F4F4F;
}