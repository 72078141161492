.BootstrapRowColCombo {
    padding: 0;
    text-align: right;
}

.Button {
    text-align: right;
    font-weight: bold;
    font-size: 15px;
    color: #53A0C6;
}