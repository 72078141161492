.TopNav {
    flex: initial;
    /*padding: 5px 20px;*/
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}

.TopMessage {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-family: "Circular-Book";
    font-size: 25px;
}

.Hamburger {
    flex: 1;
    align-items: center;
    display: flex;
}

.RightLogoWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Logo {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.Logo img {
    align-items: flex-end;
    height: 44px;
}


.LeftArrow input {
    display: none;
}

.LeftArrow {
    display: block;
    position: relative;
    width: 30px;
}

.LeftArrow label {
    width: 30px;
    height: 3.5rem;
    position: relative;
    display: block;
    cursor: pointer;
}

.LeftArrow span {
    position: relative;
    top: 1.5rem;
}

.LeftArrow span, .LeftArrow span:before, .LeftArrow span:after {
    display: block;
    background: #979797;
    width: 30px;
    height: 3px;
    border-radius: 0.25px;
}

.LeftArrow span:before, .LeftArrow span:after {
    content: '';
    position: absolute;
}

.LeftArrow span:before {
    bottom: 10px;
}

.LeftArrow span:after {
    top: 10px;
}

.LeftArrow label span:before {
    transform-origin: top left;
}

.LeftArrow label span:after {
    transform-origin: bottom left;
}

.Hamburger :checked ~ label span:before, .Hamburger :checked ~ label span:after {
    width: 60%;
}

.Hamburger :checked ~ label span:before {
    bottom: -0.125rem;
}

.Hamburger :checked ~ label span:after {
    top: -0.125rem;
}

.LeftArrow :checked ~ label span:before {
    transform: rotate(-40deg);
}

.LeftArrow :checked ~ label span:after {
    transform: rotate(40deg);
}

.x :checked ~ label span {
    width: 0;
}

.x :checked ~ label span:before {
    transform: rotate(45deg);
    bottom: 0;
}

.x :checked ~ label span:after {
    transform: rotate(-45deg);
    top: 0;
}

.x1 :checked ~ label span {
    background: none;
}

.x1 :checked ~ label span:before {
    animation: x1Before 500ms forwards;
}

.x1 :checked ~ label span:after {
    animation: x1After 500ms forwards;
}

@keyframes x1Before {
    50% {
        bottom: 0;
        transform: rotate(0deg);
    }
    100% {
        bottom: 0;
        transform: rotate(45deg);
    }
}

@keyframes x1After {
    50% {
        top: 0;
        transform: rotate(0deg);
    }
    100% {
        top: 0;
        transform: rotate(-45deg);
    }
}

.ModeButtonWrapper {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.ModeButtonWrapper div {
    margin-right: 20px;
    cursor: pointer;
    font-size: 25px;
    display: flex;
    align-items: center;
}
.ModeButtonWrapper div:hover {
    color: grey;
}

.ModeButton {

}

.ModeButtonSelected {
    color: #2a87d0;
}

