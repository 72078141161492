.RoleRequestSection {

}


.AddRoleWrapper {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 30px;
    background-color: #FFFFFF;
}

.DeleteModal {
    display: flex;
    flex-direction: column;
    justify-content: center;
}