.FilterItem {
    padding: 10px;

    margin-bottom: 1px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
}

.FilterItem:hover {
    background-color: lightgray;
}

.FilterActive {
    background-color: #6EB8D4;
}

.Title {
    text-transform: capitalize;
}