
.Row {
    border-bottom: solid 2px #E0E0E0;
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.CountIndicator {
    width:30px;
    height: 30px;
    background-color: #2CA31F;
    border-radius: 10px;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 18px;
    color: #FFFFFF;
    margin-right: 10px;
}
.CountIndicatorWaiting {
    width:30px;
    height: 30px;
    background-color: #F3C825;
    border-radius: 10px;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 18px;
    color: #FFFFFF;
    margin-right: 10px;
}

.AddIndicator {
    cursor: pointer;
    width:30px;
    height: 30px;
    background-color: #2891BF;
    border-radius: 10px;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 18px;
    color: #FFFFFF;
    margin-right: 10px;
}

.RemoveIndicator {
    cursor: pointer;
    width:30px;
    height: 30px;
    background-color: darkred;
    border-radius: 10px;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 18px;
    color: #FFFFFF;
    margin-right: 10px;
}

.PenIcon {
    margin-right: 5px;
}

.NameAreaWrapper {
    display: flex;
}

.TalentName {
    font-size: 19px;
    color: #317FD3;
}

.TalentCompany {
    font-size: 19px;
    text-align: right;
}

.RecommendedTalentBadge {
    margin-left: 6px;
    color: #1A234A;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    background-color: #EEEEEE;
    border-radius: 50%;
    padding: 6px;
}
