


.MustHaveTagItem {
    background-color: #51B9DA;
    color: #ffffff;
    padding: 5px 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    overflow-wrap: break-word;
}