.LastSurfacedClock {
    text-align: center;
    padding: 6px 0;
    cursor: pointer;
}

.Icon {
    font-size: 20px;
}

.DateWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.PopOver > div:nth-child(2) {
    min-width: 200px;
    height: 70px;
    color: #242424;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: center;
    display: flex;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
}

