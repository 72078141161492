.LittleIconWrapper {
    border: solid 2px #969696;
    border-radius: 6px;
    width: 19px;
    height: 19px;
    text-align: center;
    font-size: 10px;
    display: inline-flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    color: #969696;
    cursor: pointer;
}

.iconActive {
    color: white;
    border: none;
}

/*.LittleIconWrapper:hover {*/
/*    background-color: green;*/
/*    color: #ffffff;*/
/*    border-color: green;*/
/*    cursor: pointer;*/
/*}*/