.MainGrid {
    display: flex;
    height: 100%;
}

.LeftColumn {

}

.RightColumn {
    width: 100%;
    display: flex;
    flex-direction: column;
}