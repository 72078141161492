.Icon {
    color: grey;
    cursor: pointer;
}

.IconHighlighted {
    color: orange;
    cursor: pointer;
}

.NotesIcon {
    text-align: center;
    color: gray;
}