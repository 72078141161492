.TalentReviewedBreakDown {
    position: relative;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 330px;
    justify-content: space-evenly;
}

.Icon {
    position: absolute;
    top: 14px;
    left:15px;
    font-size: 30px;
}

.ItemsWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    justify-content: space-between;
    padding-bottom: 10px;
}

.Title {
    margin-bottom: 20px;
    font-family: 'Roboto', sans-serif;
    font-size: 22px;
    color: #242424;
}


.Item {
    display: flex;
    flex-direction: row;
    padding: 5px 2px 0;
    align-items: center;
}

.Value {
    font-family: 'Roboto', sans-serif;
    font-size: 22px;
    text-align: center;
    min-width: 50px;
    margin-right: 7px;
}

.ItemTitle {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #242424;
    font-size: 19px;
}

.SkipType {
    color: #838485;
}