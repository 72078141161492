.HelpIcon {
    align-self: center;
    width: 16px;
    height: 16px;
    color: #4BA7C3;
    margin-left: 10px;
}

.IsDriveThroughLabel {
    color: #242424;
    margin: '10px 0 20px 0';
    font-family: Helvetica, sans-serif;
    font-size: '16px';
    font-style: 'normal';
    font-weight: 400;
    line-height: 'normal';
}

.LabelWithPopover {
    display: flex;
    align-items: center;
}

.CancellationInfoBox {
    border: 1px solid red;
    padding: 11px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    line-height: 18.4px;
    gap: 4px;
    font-family: 'Helvetica', sans-serif;
    font-weight: 400;
    font-size: 16px;
    margin-left: 15px;
    width: 97%;
}

.ExtendTrialInfoBox {
    background-color: #FFC4001A;
    padding: 10px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    line-height: 18.4px;
    gap: 4px;
    font-family: 'Helvetica', sans-serif;
    font-weight: 400;
    font-size: 16px;
    margin-left: 15px;
    width: 97%;
}

.IconExclamationTriangle {
    align-self: center;
    width: 18px;
    height: 18px;
}
