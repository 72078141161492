.DatePicker {
    width: 100%;
}


.FollowUpWrapper > div:last-child {
    border-bottom: 1px solid #E6E6E6;
}

.DatePicker {
    width: 100%;
}


.InterViewWrapper > div:last-child {
    border-bottom: 1px solid #E6E6E6;
}

.DeleteButton {
    margin-bottom: 15px;
}

.ContactOutWrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.HelpIcon {
    position: relative;
    top: 2px;
    color: #4BA7C3
}

.FormGroup > div:first-of-type {
    display: block;
    width: 100%;
}

/*Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
    .DeleteButton {
        margin-bottom: initial;
    }
}