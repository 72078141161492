.feedback_container {
    display: grid;
    padding: 12px;
    background-color: #FFF;
    border: 1px solid #DFE1E1;
    border-radius: 5px;
    margin-bottom: 10px;
}

.feedback_container_row {
    display: flex;
    align-items: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #242424;
}

.feedback_image {
    width: 30px;
    height: 30px;
    margin-right: 8px;
}

.feedback_timer { 
    text-align: right;
    color: #B3B3B3;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    padding-right: 30px;
}

.feedback_description {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #4F4F4F;
    margin-top: 10px;
}

.avatar {
    width: 33.5px;
    height: 33.5px;
    background-color: #F5F5F5;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.avatar_name { 
    color: #7039ED;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 15px;
    text-align: center;
}

.inactive_avatar_name { 
    color: #7039ED;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 15px;
    text-align: center;
    filter: brightness(210%);
}

.inactive_user_name {
    color: #B3B3B3;
}