.Row {
    padding-top: 10px;
    padding-bottom: 10px;
}

.Row label {
    min-height: 14px;
}

.AcceptedIcon {
    margin-left: 3px;
    color: #28b521;
}

.SkippedIcon {
    margin-left: 3px;
    color: #545454;
}

.RejectedIcon {
    margin-left: 3px;
    color: red;
}

.InterviewIcon {
    margin-left: 3px;
    color: darkgoldenrod;
}

.ScheduledAtIcon {
    margin-left: 3px;
    color: deepskyblue;
}

.RefusedIcon {
    margin-left: 10px;
    color: blueviolet;
}

.WaitingIcon {
    margin-left: 3px;
    color: #979797;
}

.CheckBox {
    padding: 0;
    margin: 0;
}


.Pink {
    background-color: pink;
}