.CompanyMenuItem {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
}

.CompanyMenuItem > ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
}

.CompanyMenuItem ul li{
    list-style: none;
    cursor: pointer;
}

.CompanyName {
    font-family: Helvetica, Arial, sans-serif;
    padding: 8px;
    border-radius: 5px;
    font-size: 17px;
    border: solid 1px transparent;
}

.CompanyName:hover {
    background-color: #0A7AA2;
    border: solid 1px #44C9C7;
    color: #FFFFFF;
}

.Drawer {
    height: 0;
    transition-property: height;
    transition-duration: 1s;
    overflow: hidden;
}

.Open .Drawer {
    transition-property: height;
    transition-duration: 1s;
    height: 106px;
}

.Open .CompanyName{
    cursor: default;
    background-color: #43d2c8;
    color: #18234d;
}

.Icon {
    margin-left: 10px;
}