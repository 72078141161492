.TagInput {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border: 1px solid #ccc;
    padding: 4px;
    border-radius: 4px;
    min-height: 36px;
}

.Tag {
    background-color: #DFF0F7;
    color: #333;
    padding: 4px 8px;
    margin: 4px;
    border-radius: 4px;
    border: 1px solid #36789F;
}