.FormGroup {
    display: flex;
    align-items: center;
    /*margin: 2px 0 2px;*/
}

.FormGroup label {
    margin-bottom: 0;
    margin-right: 5px;
    flex-shrink: 0;
}

.Checkbox {
    margin-top: 0;
    user-select: none;
    color: #4F4F4F;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 17px;
    padding-bottom: 0;
}

.Popup {
    background: rgba(255, 255, 255, 1);
    border-radius: 5px;
    box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.25);
    padding: 0 20px;
}