.Panel {
    border: none;
    margin: 0;
}


.HiddenPanelBody {
    margin-top: 10px;
    padding: 0 0 10px;
    border-bottom: #E6E6E6 solid 1px;
}


.SubmitRow {
    margin-bottom: 15px;
}


.DatePicker {
    width: 100%;
}

.AddTalentRowWrapper .AddTalentRowItem{
    margin-bottom: 10px;
    border-bottom: solid lightgray 1px;

}

.AddTalentRowWrapper .AddTalentRowItem:last-child {
    border-bottom: none;
    padding-bottom: inherit;
    margin-bottom: inherit;
}

.AddTalentRow {
    color: green;
    cursor: pointer;
    font-weight: bold;
    font-size: 17px;
}

.HiddenLabelsLarge{
    display: none;
}

.ExternalLinkAltIcon {
    padding-left: 10px;
    cursor: pointer;
    color: #40A5D0;
}

.ButtonsRow{
    display: flex;
    justify-content: start;
}


/* Extra small devices (portrait phones, less than 576px) */
/* No media query for `xs` since this is the default in Bootstrap */

/*Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
}

/*Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
    .HiddenLabelsLarge{
        display: inline-block;
    }
}

/*Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
}