.MetricsSection {

}

.CloseIcon {
    color: #737373;
    position: absolute;
    top: 13px;
    right: 13px;
    z-index: 99;
    cursor: pointer;
}

.RoleSelectInput {
    width: 56rem !important;
    height: 40px !important;
    border: 1px solid #DFE1E1 !important;
    border-radius: 10px !important;
    padding: 15px 10px !important;
}