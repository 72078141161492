.roleTag__jobTitle {
    font-family: Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 14px;
    color: #4ba7c3;
    margin-right: 20px;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 6px;
}

.roleTag__jobTitle:hover {
    color: #0A7AA2;
}

.roleTag__jobTitleDark {
    font-family: Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 14px;
    color: #285f90;
    margin-right: 20px;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 6px;
}

.BottomBlueTextArea {
    padding: 6px 12px 0 12px;
    margin-left: 10px;
}