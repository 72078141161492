.Auth {
    text-align: center;
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
}

.FormWrapper{
    width: 80%;
    margin: 0 auto;
    text-align: left;
}

.Logo {
    width: 100px;
}

.Header {
    font-family: "Circular-Book";
}

.inputGroup{
    text-align: center;
    margin-bottom: 15px;
}

.Button {
    width: 100%;
}

.ForgotPassword {
    font-family: "Circular-Book";
    margin: 30px 0;
}




/*
Fixed the issue with small landscape mobile views.
Allows scrolling.
*/
@media (max-height: 599px) {
    .Auth {
        text-align: center;
        position: relative;
        top: 0;
        transform: inherit;
    }
}


/*
Extra small devices (portrait phones, less than 576px)
No media query since this is the default in Bootstrap
*/

/*Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {

}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
    .FormWrapper{
        border: 1px solid #979797;
        background: #d4def1;
        background: -moz-linear-gradient(top, #d4def1 0%, #dce6f7 100%);
        background: -webkit-linear-gradient(top, #d4def1 0%,#dce6f7 100%);
        background: linear-gradient(to bottom, #d4def1 0%,#dce6f7 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d4def1', endColorstr='#dce6f7',GradientType=0 );
        padding: 80px;
        width: 60%;
        max-width: 800px;
    }

    .Form {
        width: 370px;
        margin: 0 auto;
    }
}

/*Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {

}

/*Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {

}

