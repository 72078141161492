.Panel {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    background: transparent;
    border-radius: 15px;
    margin-bottom: 20px;
    overflow: hidden;
}

.Heading {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: #18234D;
    padding: 2px 15px;
    color: #FFFFFF;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 22px;
}

.Body {
    padding: 18px 0;
    background-color: #FFFFFF;
}