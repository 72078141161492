.Label {
  display: flex;
  justify-content: left;
  align-items: center;
}

.Icon {
  margin-left: 5px;
  cursor: pointer;
  color: #4ba7c3;
}

.check_img {
  position: absolute;
  right: 0;
  width: 16px;
}
