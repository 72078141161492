.PriorityDotOff {
    position: relative;
    top: -1px;
    color: grey;
    font-size: 8px;
}

.PriorityDotOn {
    position: relative;
    top: -1px;
    color: #ffe0e0;
    font-size: 8px;
}

.PriorityDotOnHold {
    position: relative;
    top: -1px;
    color: #CDC8E1;
    font-size: 8px;
}


.TalentButton,
.RoleInfoButton,
.PriorityButton,
.TalentReminderButton,
.InfoReminderButton,
.DeleteRow,
.ExportButton,
.LastReviewed {
    margin-bottom: 15px;
}

.CalendarFooter {
    border-top: solid 1px #C4C4C4;
    float: left;
    width: 100%;
    padding: 6px;
    text-align: right;
    clear: both;
}

.CalendarFooterButton {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 16px;
    color: #838485;
    padding: 1px 8px;
    border-radius: 4px;
    background-color: transparent;
}

.CalendarFooterButton:hover {
    background-color: #E5E5E5;
    color: #838485;
    text-decoration: none;
}

.SmallIconButtonSpace {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

/*Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
    .TalentButton, .RoleInfoButton, .PriorityButton, .TalentReminderButton, .InfoReminderButton, .DeleteRow {
        margin-bottom: initial;
    }
}