.ClientRequestHistory {
    display: flex;
    flex-direction: row;
    justify-content: center;
}


.OpenIcon {
    position: relative;
    top: 4px;
    margin-right: 5px;

    color: #e13e6a
}

.CloseIcon {
    position: relative;
    top: 4px;
    margin-right: 5px;
    color: #51B9DA;
}