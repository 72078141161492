.AddRowSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #319341;
    font-size: 22px;
    cursor: pointer;
}

.AddRowInput {
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    width: 50px;
}

.AddRowPlusIcon {
    margin-right: 10px;
    color: #319341;
}