.HeaderOptionsItem {
    white-space: nowrap;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 18px;
    padding: 6px 11px;
    min-width: 150px;
    margin-bottom: 4px;
}

.HeaderOptionsItem:hover {
    background: #EEEEEE;
    cursor: pointer;
    border-radius: 8px;
}

.HeaderOptionsItem:last-child {
    border: none;
    margin-bottom: 0;
}

.Icon {
    margin-right: 10px;
}