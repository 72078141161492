.TalentForReviewForm {
    margin-bottom: 20px;
}

.TalentForReviewForm > div {
    box-shadow: none;
    border-bottom: 2px solid grey;
}

.TalentForReviewForm > div:last-child {
    border-bottom: none;
}

.OptionsWrapper {
    display: flex;
    flex-direction: row;
}

.PanelTitle {
    font-size: 23px;
    color: #000000;
}

.HeaderIconsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.HeaderOpenIndicator {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.DownArrow {
    color: #000000;
}

.DownArrowActive {
    transform: rotate(180deg);
}
