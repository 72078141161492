.Active, .Active:hover {
    color: #43d2c8;
    cursor: default;

}

.Default {
    padding: 3px;
}

.Default:hover {
    color: #c3f6f4;
}