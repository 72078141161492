.IconInput {
    border: solid #9B9B9B 1px;
    background-color: #ffffff;
    margin-bottom: 20px;
    display: flex;
    height: 78px;
    border-radius: 3px;
}

.Errors {
    border-color: red;
}

.ErrorMessages {
    color: red;
    margin-right: 10px;
}

.Icon {
    display: flex;
    margin-right: 1px;
    width: 80px;
    font-size: 25px;
}

.Input {
    padding-left: 10px;
    padding-right: 10px;
    flex: 1 1 auto;
    width: 100%;
    font-size: 24px;
    color: #4A4A4A;
    border: none;
}

.FontAwesome{
    width: 100%;
    text-align: center;
    padding-top:25px;
    color: #9B9B9B;
}
