.TalentProspectItem {
    /*border-bottom: solid 2px darkgrey;*/
    padding-top: 10px;
    padding-bottom: 10px;
}

.TalentName {
    font-size: 19px;
    color: #317FD3;
}

.TalentCompany {
    font-size: 19px;
    text-align: right;
}

.TalentOptions {
    padding-top: 4px;
    text-align: right;
    padding-bottom: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.PenIcon {
    margin-right: 5px;
}

.RecommendedTalentBadge {
    margin-left: 6px;
    color: #1A234A;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    background-color: #EEEEEE;
    border-radius: 50%;
    padding: 6px;
}