


.Header {
    padding: 15px;
}


.Title {
    font-size: 20px;
    font-weight: bold;
}



.HeaderMessage {
    font-size: 15px;
}


.Overlay {
    background-color: red;
}