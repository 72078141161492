.BubbleWrapper span:first-child {
    margin: 0;
}

.Bubble {
    margin-left: 5px;
}

.Star {
    font-size: 6px;
    height: 8px;
    margin-right: 2px;
}