.Counter {
    background: rgba(225, 62, 106, 1);
    color: #FFFFFF;
}

.funnelIcon {
    font-size: 15px;
    margin-right: 4px;
    top: 1px;
    position: relative;
    cursor: pointer;
}

.sortIcon {
    font-size: 20px;
    margin-right: 4px;
    top: 2px;
    position: relative;
    cursor: pointer;
}