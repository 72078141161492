.ModalBackground {
    background-color: rgba(0,0,0,.3);
    position: fixed;
    z-index: 1040;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    justify-content: center;
}

/* this fixes and issue where bootstrap adds inner layers to the modal and we need to center it like the mock up */
.ModalBackground > div, .ModalBackground > div  > div {
    height: 100%;
}


.ModalWrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Modal {
    background-color: #FFFFFF;
    border-radius: 15px;
}




.ItemsWrapper {

}

.Footer {
    padding: 15px;
}

.CounterButton {
    padding: 6px 14px;
    margin-right: 5px;
    display: inline-block;
    border-radius: 5px;
    color: #FFF;
    text-align: center;
    background-image: linear-gradient(to bottom,#3375B7 0,#3375B7 100%);
}


/* Extra small devices (portrait phones, less than 576px)*/
/* No media query since this is the default in Bootstrap*/

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {

}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
    .Modal {
        min-width: 950px;
    }
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
    .Modal {
        min-width: 1200px;
    }
}