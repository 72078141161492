.ContactOutTag {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #51B9DA;
    color: #FFFFFF;
    border-radius: 8px;
    margin-bottom: 10px;
    margin-right: 10px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    padding: 5px 10px;
}

.Icon {
    margin-right: 5px;
}