.FilterButton {
    border: solid 2px lightgray;
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
    cursor: pointer;
    border-radius: 5px;
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
}

.Spacer {
    min-width: 15px;
    min-height: 15px;
}