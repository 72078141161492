.RoleLimitDisplay {
    border: 2px solid #DFE1E1;
    border-radius: 10px;
    font-family: Helvetica, Arial, sans-serif;
    color: #4F4F4F;
    padding: 4px 10px;
    font-size: 17px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    cursor: pointer;
}

.Column {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.Numbers {
    font-size: 18px;
    line-height: 19px;
    font-family: Helvetica, Arial, sans-serif;
    color: #4F4F4F;
}

.Titles {
    font-size: 12px;
    line-height: 13px;
    color: #838485;
    font-family: Helvetica, Arial, sans-serif;
}

.Divider {
    color: #c4c4c4;
    font-size: 18px;
    font-family: Helvetica, Arial, sans-serif;
}
