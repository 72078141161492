.UserItem {
    background-color: #FFFFFF;
    margin-bottom: 10px;
    box-shadow: 0px 0px 4px 2px rgba(0,0,0,0.24);
}

.ColumnWrapper {
    min-height:  80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.TopSection {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #EAEAEA;
}


.UserSummarySection {
    display: flex;
    flex-direction: column;
}


.Avatar {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: #c4c4c4;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin-right: 15px;
    color: #FFFFFF;
}


.Name {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 17px;
}
.Email {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: unset;
    color: #b3b3b3;
}