.LabelText {
    white-space: nowrap;
    padding-right: 10px;
    font-weight: bold;

}

/*Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
    .DropDownTalentStatusRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

}