.NoFilteredTalent {
    text-align: center;
    padding: 50px 0;
}

.Robot {
    position: relative;
    left: 20px;
    margin-bottom: 40px;
    max-width: 400px;
}