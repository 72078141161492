.ColumnWrapper {
    min-height:  80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.nameSection {
    float: left;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #242424;
    margin-bottom: 2px;
    padding-left: 15px;
}

.invisibleIdSection {
    padding-left: 10px;
    float: left;
    color: #F7F5F5;
}

.Avatar {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: #c4c4c4;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin-right: 15px;
    color: #FFFFFF;
}

.detailButton {
    width: 129px;
    height: 32px;
    background: linear-gradient(180deg, rgba(89, 181, 89, 0.89) 0%, #429742 100%);
    border: 1px solid #DFE1E1;
    border-radius: 2px;
}

.detailButton:hover {
    background: linear-gradient(180deg, rgba(89, 181, 89, 0.89) 0%, #429742 100%);
}

.buttonText {
    font-family: 'Helvetica';
    color: white;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
}

.matchName {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #242424;
    margin-bottom: 2px;
}

.matchLocation {
    font-family: 'Helvetica';
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #838485;
    margin-bottom: 2px;
}

.matchTabs {
    padding-top: 10px;
}

.jobTitle {
    width: 94px;
    /* left: 502px; */
    /* top: 79.73%; */
    /* bottom: 18.61%; */

    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    /* display: flex; */
    /* align-items: flex-end; */

    color: #242424;
}

.jobCompany {
    width: 141px;
    left: 502px;
    top: 82.08%;
    margin-top: 10px;
    margin-left: 21px;
    bottom: 16.26%;

    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;

    color: #4F4F4F;
}

.jobDuration {
    margin-left: 21px;
    color: #838485;
    line-height: 17px;
}

.jobDescription {
    margin-left: 21px;
    color: #242424;
    line-height: 17px;
}

.currentPositionBox {
    border-bottom: #838485;
}

.position {
    padding-top: 10px;
    font-family: 'Roboto';
    font-weight: 500;
    color: #242424;
    font-size: 16px;
    line-height: 19px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.profileImage {
    border-radius: 45px;
    box-sizing: border-box;
    width: 60px;
    height: 60px;
    left: 476px;
    top: 635px;
    border: 1px solid #FFFFFF;
}

.resultsCard {
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
    width: unset;
    margin-left: 15px;
    margin-right: 15px;
    height: auto;
    left: 456px;
    top: 617px;
    background: #F7F5F5;
    border: 1px solid #DFE1E1;
    cursor: pointer;
}

.updatedDate {
    width: 131px;
    height: 15px;
    left: 1097px;
    top: 636px;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    text-align: right;
    color: #838485;
}

.Icon {
    margin-right: 4px;
    margin-top: 4px;
}

.resultLocation {
    position: absolute;
}

.jobIcon {
    color: #838485;
    height: 16px;
    width: 16px;
    margin-right: 5px;
}

.jobTitleDescription {
    position: absolute;
}

.candidateActivity {
    margin-top: 20px;
}

.noteContainer {
    width: 100%;
    height: 100%;
}

.noteTitle {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #242424;
    margin-top: 10px;
}

.noteSection {
    background-color: #F5F5F5;
    border: 1px solid #DFE1E1;
    border-radius: 5px;
    box-sizing: border-box;
    margin-top: 10px; 
    padding: 10px;

}
