

.PopUpWrapper {
    position: relative;
}

.Calendar {
    width: 100%;
    margin-bottom: 10px;
}

.Calendar > div {
    width: 100%;
}

.Calendar .CalendarDay {
    width: 2.4rem;
    height: 2.4rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
}

.CalendarDay {

}