.OuterTag {
    background-color: white;
    border: solid #b3b3b3 1px;
    border-radius: 3px;
    margin-right: 3px;
    display: inline-flex;
    margin-bottom: 3px;
    user-select: none;
}

.InnerTag {
    display: flex;
    align-items: center;
    padding: 0 5px;
}

.closeSection {
    display: flex;
    justify-content: center;
    font-size: 12px;
    padding: 0;
    align-items: center;
    border-left: solid 1px #242424;
    margin: 0;
    width: 15px;
    cursor: pointer;
    z-index: 1000;
    user-select: none;
}