.MetricRow {
    cursor: pointer;
    font-size: 16px;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: unset;
    margin-bottom: 8px;
    color: #242424;
    padding: 2px 10px;
}

.MetricRow:hover {
    background-color: #EEEEEE;
    border-radius: 5px;
}

.MetricRow:last-child {
    margin-bottom: 0;
}

.AddRow {
    color: #838485;
    margin-left: 5px;
    font-size: 13px;
}

.LocationRow {
    font-family: Helvetica, sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    margin: 4px 0;
    color: #838485;
}