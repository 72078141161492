.RoleFilterBox {
    user-select: none;
    padding-bottom: 5px;
}

.Button {
    height: 35px;
    align-items: center;
    font-size: 18px;
    text-align: center;
    color: rgba(36, 36, 36, 1);
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    cursor: pointer;
    user-select: none;
    border-radius: 5px;
    line-height: 1;
}

.ButtonLookalike {
    height: 35px;
    align-items: center;
    font-size: 14px;
    text-align: center;
    color: rgba(36, 36, 36, 1);
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    border-radius: 5px;
    line-height: 1;
}

.DisabledButton {
    height: 35px;
    align-items: center;
    font-size: 14px;
    text-align: center;
    color: grey;
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: center;
    pointer-events: none;
    cursor: none;
    user-select: none;
    border-radius: 5px;
    line-height: 1;
}

.Button:hover {
    background-color: #EEEEEE;
}

.ButtonLookalike:hover {
    background-color: #EEEEEE;
}

.Button.active {
    background: rgba(249, 216, 225, 1);
    color: rgba(225, 62, 106, 1);
}

.ButtonLookalike.active {
    background: rgba(249, 216, 225, 1);
    color: rgba(225, 62, 106, 1);
}

.PopUpWrapper {
    position: relative;
}
