.OrPadding {
    margin-right: 10px;
    margin-left: 10px;
}

.DeleteRow {
    margin-bottom: 15px;
}


.TrashCan {
    margin-left: 10px;
}

.Alert {
    margin-bottom: 15px;
}