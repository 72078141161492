
.Wrapper {
    position: relative;    
}

.DropDown {
    background: white;
    border: solid 1px #c4c4c4;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 100;
    max-height: 120px;
    overflow-y: scroll;
    overflow-x: hidden;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.IndustryItem {
    padding: 5px;
    cursor: pointer;
}

.NoResultsItem {
    padding: .22222rem;
    border: solid 1px #c4c4c4;
    left: 0;
    right: 0;
}