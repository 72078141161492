.DragContainerInput {
    display: inline-flex;
    min-width: 75px;
    flex: 1;
    max-width: inherit;
    border: none;
    margin-bottom: 3px;
}
.DragContainerInput:focus {
    outline: none;
    border:none;
}

.DragContainerInput {
    width: 100%;
}

.DragContainerInput:focus {
    outline: none;
}