.roleTag__formGroup {
    margin-bottom: 3px;
    margin-top: 8px;
}

.FormGroup {
    margin-bottom: 3px;
    margin-top: 8px;
}

.roleTag_label {
    color: #242424;
    font-size: 15px;
    white-space: nowrap;
}

.locationsFilter_label {
    color: #242424;
    font-size: 10px;
}

.Popup {
    background: rgba(255, 255, 255, 1);
    border-radius: 5px;
    box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.25);
    width: 300px;
    min-height: 100px;
    padding: 10px;
}

.Robot {
    position: relative;
    margin-bottom: 10px;
    max-width: 100px;
}

.NoLocations {
    text-align: center;
    padding: 10px 0;
}

.clearAll {
    background: none;
	color: "#838485";
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.clearAllSkills {
    background: none;
	color: "#838485";
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}