.Header {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 30px;
    color: #18234D;
    margin-top: 0;
    margin-bottom: 0;
}

.HeaderWrapper {
    margin-top: 30px;
    margin-bottom: 25px;
}

.Archived {
    font-size: 20px;
    margin-left: 10px;
    top: -3px;
    position: relative;
    background: #DFE1E1;
    color: #242424;
    border-radius: 3px;
    padding: 3px 12px;
    font-family: 'Roboto', sans-serif;
}


.CheckBox input {
    top: 3px;
}

.toastTitle {
    font-weight: bold;
    font-size: 14px;
    padding-bottom: 6px;
}