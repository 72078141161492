.Button {
    font-family: Helvetica, Arial, sans-serif;
    color: #838485;
    margin-bottom: 10px;
    padding: 4px 10px;
    font-weight: 500;
    font-size:  20px;
}
.Button:hover {
    text-decoration: none;
    background-color: #DDDDDD;
    color: black;
}

.ActiveButton {
    text-decoration: none;
    border-radius: unset;
    color: #43d2c8;
    border-bottom: solid 3px #43d2c8;

}

.ActiveButton:active, .ActiveButton:hover {
    text-decoration: none;
    border-radius: unset;
    color: #43d2c8;
    border-bottom: solid 3px #43d2c8;
    background-color: #FFFFFF;
}

.ActiveButton:focus {
    text-decoration: none;
    border-radius: unset;
    color: #43d2c8;
    border-bottom: solid 3px #43d2c8;
    background-color: #FFFFFF;
}