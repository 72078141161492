.ButtonWrapper .DisabledButton {
    background-color: green;
    color: #FFFFFF;
    background-image: none;
}


.ButtonWrapper .DisabledButton:focus {
    background-color: green;
    color: #FFFFFF;
    background-image: none;
}