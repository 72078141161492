.LeftColumnWrapper {
    position: relative;
    background-color: #18234D;
    color: #FFFFFF;
    height: 100%;
    /*border: solid 2px lightgray*/
}

.LeftColumn {
    display: flex;
    flex-direction: column;
    width: 0;
    opacity: 0;
    transition: width 800ms ease 600ms, opacity 800ms ease;
    overflow: hidden;
    padding: 0 20px;
    height: 100%;
}

.Open .LeftColumn  {
    width: 250px;
    opacity: 1;
    transition: width 1000ms ease, opacity 1s 800ms;
}

.CompaniesWrapper {
    overflow: hidden;
    overflow-y: auto;
    margin-right: -25px;
    flex: 1;
}

.Divider {
    border-top: 0.5px solid #C4C4C4;
}