.ReviewedTalentSection {
    padding-top: 0;
    padding-bottom: 0;
}

.ReviewedTalentSectionBody {
    padding-bottom: 20px;
}

.HeaderIconWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}


.Panel {
    border: none;
    background-color: transparent;
    overflow: hidden;
    margin-bottom: 0;
    border-radius: unset;
}

.PanelHeading {
    background-color: green;
}


.ItemWrapper {
    /*border-bottom: solid 2px darkgrey;*/
    padding-top: 10px;
    padding-bottom: 10px;
}