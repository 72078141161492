.FilterSection {
    padding-top: 15px;
    padding-bottom: 20px;
}

.FilterSection:last-child {
    padding-bottom: 0;
}

.Title {
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: #4F4F4F;
    margin-bottom: 25px;
}