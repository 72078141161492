.SideDrawer {
    background-color: #ffffff;
    z-index: 1001;
    box-shadow: 5px 0 5px rgba(0,0,0,.15);
    width: 17em;
    min-height: 100%;
    max-height: none;
    border-width: 0;
    position: absolute;
    top: 0;
    display: block;
    
}

.Open {
    transition: .2s ease-in-out;
    transform: translate3d(0,0,0);
}

.Close {
    transition: .2s ease-in-out;
    transform: translate3d(-17em,0,0);
}

.SideDrawer ul, .SideDrawer li {
    list-style: none;
    margin: 0;
    padding: 0;
}

.SideDrawer li {
    text-align: center;
}


.SideDrawer a, .SideDrawer span{
    display: block;
    padding: 20px 20px;
    text-decoration: none;
    font-family: "Circular-Book";
    font-size: 20px;
    color: #4A4A4A;
    border-bottom: 1px solid #4A4A4A;
    cursor: pointer;
}

.SideDrawer a:hover{
    font-weight: bold;
}