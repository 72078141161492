.RoleStatusHistory {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.TextWrapper {
    text-align: center;
    min-width: 110px;
}