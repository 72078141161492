.UserWrapper {
    max-height: 200px;
    overflow-y: auto;
    background-color: #FFFFFF;
    border-top: solid 1px #c4c4c4;
}

.UserItem {
    padding: 5px;
    margin: 10px 10px;
}

.UserItem:hover {
    background-color: #B6DBE8;
    cursor: pointer;
}

.Name {
    margin-right: 10px;
    font-weight: bold;
}

.Email {

}