.Panel {
    margin-bottom: 0;
    border: none;
    padding-bottom: 10px;
    background-color: #FFFFFF;
}

.RoleContentWrapper {
    padding-top: 15px;
    border-left: solid 1px #E6E6E6;
    border-right: solid 1px #E6E6E6;
}

.InfoGreyBox {
    background-color: #F7F7F7;
    border: solid 1px #E6E6E6;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.Pre {
    font-family: Helvetica;
    font-size: 10px;
    width: 100%;
    white-space: pre-wrap;
    word-break: break-word;
    border: none;
    padding: 0;
    background-color: unset;
}


.TalentButton, .RoleInfoButton, .PriorityButton, .TalentReminderButton, .InfoReminderButton, .DeleteRow {
    margin-bottom: 15px;
}

/*Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
    .TalentButton, .RoleInfoButton, .PriorityButton, .TalentReminderButton, .InfoReminderButton, .DeleteRow {
        margin-bottom: initial;
    }
}