.SmallPercentBox {
    position: relative;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 330px;
    justify-content: space-evenly;
}

.Icon {
    position: absolute;
    top: 14px;
    left:15px;
    font-size: 30px;
}


.PercentWrapper {
    flex: 1;
    height: 100px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}


.CenterPercentage {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 30px;
}

.Title {
    font-family: 'Roboto', sans-serif;
    font-size: 22px;
    font-weight: normal;
    color: #242424;
    flex: 1;
}

.StatsWrapper {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 18px;
    line-height: 1.2;
    color: #4F4F4F;
    text-align: center;
    flex: 1;
}

.StatsWrapper div:first-child{
    margin-bottom: 7px;
}