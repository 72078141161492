.Label {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #242424;
}


.DatePickerInput {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.DatePickerInput:focus {
    border-color: #66afe9;
    outline: 0;-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
    box-shadow: inset 0 1px 1px
    rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
}

.DatePickerInput[disabled] {
    background-color: #eee;
    opacity: 1;
}

.FormGroup > div:first-of-type {
    display: block;
    width: 100%;
}


/*Horrible important but its needed to fix the calendar css mess.*/
.CalendarFix > div:nth-child(1) {
    left: 77px !important;
}
