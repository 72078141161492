.AutoCompleteListWrapper{
    position: relative;
}

.AutoCompleteList {
    background: #FFFFFF;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 5000;
    height: 180px;
    overflow-y: auto;
    border-radius: 4px;
    margin-top: 2px;
    box-shadow: 0px 1px 4px 0px #83848573;
    padding: 6px;
}

.AutoCompleteListItem {
    /*background: pink;*/
    margin-bottom: 3px;
    padding: 5px 8px;
    font-size: 14px;
    color: #242424;
}

.AutoCompleteListItem:hover {
    background-color: #DCF1F8;
    cursor: pointer;
}