.ArchiveLeftMenuSection {
    border-top: solid 2px white;
    padding: 15px 0;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Icon {

}