.ColumnHeader {
    margin: 0;
    display: block;
    border-bottom: solid 2px lightgray;
    padding: 15px 0;
    font-size: 22px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
}

