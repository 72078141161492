.CreateRoleScreen {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
}


.RolebotImage {
    margin-bottom: 30px;
}

.Header {
    margin-bottom: 30px;
}

.Message {
    margin-bottom: 30px;
}


/*Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
    .CreateRoleScreen {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}
