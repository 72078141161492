.RoleInfoSection {
}

.Panel {
    margin: 0;
    border-radius: unset;
    box-shadow: none;
    border: none;
}

.PanelBody {
    padding: 0;
}

.InfoGreyBox {
    background-color: #F7F7F7;
    border: solid 1px #E6E6E6;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 15px;
}


.Buttons {
    margin-bottom: 15px;
}