.DragContainer {
    background-color: #FFFFFF;
    padding: 6px 12px 0 12px;
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    border-radius: 4px;
    border: 1px solid #DFE1E1;
    transition: border-color 300ms ease-in-out;
}

.DragContainerFocused {
    border-color: #0A7AA2;
}