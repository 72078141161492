.HeaderOptionsButtonWrapper {
    position: relative;
}

.HeaderOptionsButton {
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f7f5f5;
    border-radius: 8px;
    cursor: pointer;
}

.HeaderOptionsButton:hover {
    background-color: #EEEEEE;
}

.PopUpWrapper {
    position: absolute;
    background-color: pink;
    right: 0;
    bottom: 0;
}

.PopUp {
    position: absolute;
    right: 0;
    z-index: 1000;
    padding: 10px;
    top: 5px;
    border-radius: 8px;
    box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.18);
    background-color: #FFFFFF;
}
