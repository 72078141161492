.IconList {
    flex-direction: row;
    display: flex;
    justify-content: flex-end;
    align-content: center;
}

.IconWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0 5px;
    align-items: center;
}

.Count {
    padding: 5px;
}

.Icon {
    margin-right: 10px;
}