.RoleRowItemTop {
    background: #F7F7F7;
    border-bottom: 1px solid #E6E6E6;
    border-left: 1px solid #E6E6E6;
    border-right: 1px solid #E6E6E6;
    font-family: Helvetica;
    font-size: 17px;
    color: #393D41;
    display: flex;
    flex-direction: row;
    font-weight: bold;
    cursor: pointer;
    align-items: stretch;
}

.RoleNameWrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.RoleName {
    text-align: center;
    align-items: center;
    display: flex;
}

.PriorityPink {
    background-color: #ffe0e0;
}

.PriorityBlue {
    background-color: #CDF1F9;
}

.PriorityOnHoldRow {
    background-color: #CDC8E1;
}

.RoleRowItemTop:first-child {
    border-top: #E6E6E6 solid 1px;
}

.AdminArea{
    padding-right: 10px;
    padding-left: 10px;
    text-align: center;
    margin-bottom: 5px;
}

.DropDownIndicator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    border-right: 1px solid #E6E6E6;
    align-self: stretch;
}

.RoleInfoSection {
    width: 100%;
    padding: 10px;
    min-width: 0;
}

.ChevronIcon {
    transition-property: transform;
    transition-duration: .5s;
}

.ChevronIconActive {
    transform: rotate(-180deg);
}

.OrderText {
    color: #979797;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 1px;
    font-size: 12px;
}

.CompanyName {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 1px;
    font-size: 18px;
    text-align: center;
}

.Status {
    min-width: 175px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
}

.ReadyStatus {
    color: green;
    font-weight: bold;
}

.RefusedStatus {
    color: blueviolet;
    font-weight: bold;
}

.WaitingForTalent {
    color: green;
}

.RejectedStatus {
    color: red;
    font-weight: bold;
    display: inline;
}

.SkippedStatus {
    color: #515151;
    font-weight: bold;
    display: inline;
}

.InterviewSet {
    color: darkgoldenrod;
    font-weight: bold;
    display: inline;
}

.DealCompletedWrapper {
    display: flex;
    flex-direction: column;
}

.DealCompleted {
    display: inline;
    color: darkgoldenrod;
    font-weight: bold;
}

.NotReadyStatus {
    color: #979797;
    display: inline;
}

.ScheduledStatus {
    color: deepskyblue;
    display: inline;
}

.WaitingForCustomer {
    color: #979797;
}

.WaitingForCard {
    color: #a94442;
}

.NumberIconsWrapper{
    display: inline-block;
    font-size: 14px;
}

.NumberIconsWrapper span{
    margin-left: 20px;
}

.NumberIconsWrapper span:first-child {
    margin-left: 0;
}

.InterestedFlagArea {
    color: green;
    margin-left: 10px;
}

.Flag {
    font-size: 14px;
}

.FlagCount {
    font-size: 14px;
}

.TrialIcon {
    background-color: #2A8EB5;
    border-radius: 5px;
    color: #fff;
    font-size: 10px;
    padding: 2px 4px;
    letter-spacing: 3px;
    margin-left: 10px;
    text-align: center;
    text-indent: 3px;
}

.SourcingIcon {
    background-color: #2D499F;
    border-radius: 5px;
    color: #fff;
    font-size: 10px;
    padding: 2px 4px;
    letter-spacing: 2px;
    margin-left: 10px;
    text-align: center;
    text-indent: 3px;
}


.AutopilotIcon {
    background-color: #028d09;
    border-radius: 5px;
    color: #fff;
    font-size: 10px;
    padding: 2px 4px;
    letter-spacing: 2px;
    margin-left: 10px;
    text-align: center;
    text-indent: 3px;
}

.ApolloIcon {
    height: 1.7rem;
    padding: 2px 4px;
    margin-left: 10px;
}

.AtsIcon {
    height: 2rem;
    padding: 2px 4px;
    margin-left: 10px;
}

/* Extra small devices (portrait phones, less than 576px) */
/* No media query for `xs` since this is the default in Bootstrap */

/*Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
}

/*Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
    .RoleInfoSection {
        display: flex;
        flex-direction: row;
    }

    .RoleName {
        text-align: left;
    }

    .CompanyName {
        text-align: left;
    }

    .AdminArea{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 0;
    }
}

/*Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
}