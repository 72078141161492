.RolesFilterInput {
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: solid 2px lightgray;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 15px;
}

.ClearButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 5px;
    color: grey;
    cursor: pointer;
}

.SearchIcon {
    margin: 0 10px;
}

.InputWrapper {
    display: flex;
    flex: 1;
    overflow: hidden;
}

.Input, .Input:focus {
    border: none;
    padding: 0;
    flex:1;
    box-shadow: none;
}

.RolesButton {
    border: none;
    color: #FFFFFF;
    background-color: lightgray;
    background-image: none;
    border-radius: unset;
    box-shadow: none;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: bold;
}

.RolesButtonActive, .RolesButton:hover {
    background-color: green;
}

.TalentButton {
    border: none;
    color: #FFFFFF;
    background-color: lightgray;
    background-image: none;
    border-radius: unset;
    box-shadow: none;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: bold;
}

.TalentButtonActive, .TalentButton:hover {
    background-color: cornflowerblue;
}

