.Form {
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 0.5px;
    /*box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.25);*/
}

.FormGroup {
    display: flex;
    align-items: center;
    /*margin: 2px 0 2px;*/
}

.FormGroup label {
    margin-bottom: 0;
    margin-right: 5px;
    flex-shrink: 0;
}

.Checkbox {
    margin-top: 0;
    user-select: none;
    color: #4F4F4F;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 17px;
}

.Popup {
    background: rgba(255, 255, 255, 1);
    border-radius: 5px;
    box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.25);
    padding: 20px;
}

.BottomText {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 16px;
    color: #b3b3b3;
    font-weight: unset;
    line-height: 1;
    text-align: justify;
}
.Dropdown {
    font-size: 15px;
    padding-left: 7px;
}