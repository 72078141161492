.TalentInterviewItem {
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: #FFFFFF;
    border-top: 1px solid #E6E6E6;
    border-left: 1px solid #E6E6E6;
    border-right: 1px solid #E6E6E6;
}

.Delete {
    text-align: right;
    color: red;
}

.CronofySpan {
    font-style: italic;
    margin-left: 10px;
    font-weight: 600;
}