.ChargeForm {
    display: flex;
    flex-direction: row;
    align-items: stretch;
}

.InputForm {
    margin-right: 5px;
}

.ChargeForm button {
    flex: 1;
}

.Pre {
    white-space: pre-wrap;
    border: none;
    padding: 0;
    margin: 0;
    background-color: unset;
}