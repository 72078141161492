.TopBackButtonSection .BackButton {
    margin-bottom: 15px;
    padding: 0;
    color: #4BA7C3;
    text-align: left;
    font-size: 16px;
    font-family: Helvetica, Arial, sans-serif;
}


.UserProfileHeader {

}

.UserProfileHeader .UserAvatar {
    text-align: center;
    background-color: #c4c4c4;
    height: 90px;
    width: 90px;
    border-radius: 50px;
    flex-direction: column;
    justify-content: center;
    display: flex;
    font-size: 45px;
    color: #FFF;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
}

.UserProfileHeader .InfoWrapper {
    min-height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;

}


.UserProfileHeader .Name {
    font-size: 25px;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
}

.UserProfileHeader .Title {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 21px;
    font-weight: unset;
}

.UserProfileHeader .Email {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: unset;
    color: #b3b3b3;
}


.UserProfileHeader .RolesCountWrapper {
    min-height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.UserProfileHeader .RolesCount {
    text-align: center;
}

.UserProfileHeader .RolesTitle {
    text-align: center;
}