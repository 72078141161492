.OpenCloseArrowButton {
    border: solid 2px #18234D;
    color: #18234D;
    position: absolute;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    right: -13px;
    top: 9px;
    z-index: 20;
    cursor: pointer;
    transform: rotateZ(0deg);
    transition: transform 1s ease;
}


.Open {
    transform: rotateZ(180deg);
    transition: transform 1s ease;
}