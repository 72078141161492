.TitleButton {
    background: #F7F7F7;
    border-bottom: 1px solid #E6E6E6;
    border-left: 1px solid #E6E6E6;
    border-right: 1px solid #E6E6E6;
    font-family: Helvetica;
    font-size: 14px;
    color: #393D41;
    height: 40px;
    -ms-flex-align: center;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.OuterWrapper {
    border-left: 1px solid #E6E6E6;
    border-right: 1px solid #E6E6E6;
    border-bottom: 1px solid #E6E6E6;
    padding-top: 15px;
    padding-bottom: 15px;
}

.InputGroup {
    border: solid 1px #DEDEDE;
    background-color: #F9F9F9;
    padding-top: 15px;
}

.UserInfoWrapper {
    padding-top: 10px;
    padding-bottom: 10px;
    min-height: 400px;
}

.DatePicker {
    width: 100%;
}

.TimeZone {
    font-size: 9px;
}

.FollowUpWrapper > div:last-child {
    border-bottom: 1px solid #E6E6E6;
}

.InterViewWrapper > div:last-child {
    border-bottom: 1px solid #E6E6E6;
}

.PriorityDotOff {
    position: relative;
    top: -1px;
    color: grey;
    font-size: 8px;
}

.PriorityDotOn {
    position: relative;
    top: -1px;
    color: green;
    font-size: 8px;
}