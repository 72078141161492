.SmallMetricItem {
    position: relative;
    background-color: #FFFFFF;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.IconWrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
}

.Title {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 17px;

}

.Value {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 25px;
}