.CreateRoleScreen {
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
}

.RolebotImage {
    margin-bottom: 10px;
}

.Header {
    margin-bottom: 10px;
}

/*Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
    .CreateRoleScreen {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}
