.LargeMetricWheel {
    position: relative;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 220px;
}

.Icon {
    font-size: 30px;
    display: flex;
}

.TitleWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    font-family: 'Roboto', sans-serif;
    font-size: 22px;
    font-weight: normal;
    color: #242424;
}

.TextWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Title {
    margin-left: 7px;
}

.ItemsWrapper {

    width: 100%;
}

.PieChartWrapper {
    height: 300px;
    width: 100%;
}

.SummaryBlock {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
    margin-left: 20px;
}
