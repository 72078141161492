.TalentPreferredExperienceRange {
    color: #4F4F4F;
    font-family: Helvetica, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.TalentPreferredExperienceRangeSpan {
    border-radius: 4px;
    border: 1px solid #C4C4C4;
    background: #EEE;
    padding: 5px 18px;
}

.roleTag_label {
    color: #242424;
    font-size: 15px;
}

.roleTag__formGroup {
    margin-bottom: 3px;
    margin-top: 8px;
}

.ButtonSliderGroup {
    text-align: center;
}

.SliderGroup{
    text-align: center;
    width: 100%;
}