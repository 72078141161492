.AddUsersToRolesModal {

}

.ReactModal {
    top: 19%;
}

.RoleRow {
    margin-bottom: 10px;
}

.RoleName {
    color: #242424;
    font-family: 'Helvetica', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 3px;
    padding-left: 0;
}

.RoleNameDisabled {
    color: #838485;
    font-family: 'Helvetica', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 3px;
    padding-left: 0;
}

.RoleOrderNumber {
    color: #838485;
    font-family: 'Helvetica', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 0;
}

.RoleStatus {
    color: #838485;
    font-family: 'Helvetica', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

input[type="checkbox"].Checkbox {
    height: 15px;
    width: 15px;
    margin-top: -8px;
}

.Input {
    height: 36px;
    border-radius: 6px;
    border: 1px solid #DFE1E1;
}

.RolesContainer {
    padding: 8px;
    height: 400px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.RolesTypeDropDown {
    margin-bottom: 15px;
    width: 100%;
}

.Header {
    padding-bottom: 0;
    border: none;
}

.Header Button {
    color: #c4c4c4;
    opacity: unset;
    font-size: 25px;
}

.Header Button:hover {
    color: #838485;
    opacity: unset;
}

.Title {
    color: #242424;
    font-family: 'Roboto', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
}

.SubHeading {
    color: #242424;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.Icon {
    color: #E43F3F;
    margin-right: 10px;
    font-size: 48px;
}

.Body {
    margin: 0 3px;
    padding-bottom: 0;
    font-size: 18px;
    font-family: Helvetica, Arial, sans-serif;
    color: #242424;
    border-bottom: 1px solid #DFE1E1;
}

.Footer {
    margin-top: 6px;
    border: none;
}

.DownLoadIcon {
    color: #18234d;
    margin-right: 10px;
    font-size: 48px;
}

/* Hide the default scrollbar */
.RolesContainer::-webkit-scrollbar {
    width: 6px; /* Set the width of the scrollbar */
}

/* Customize the scrollbar thumb (draggable part) */
.RolesContainer::-webkit-scrollbar-thumb {
    background: #C4C4C4; /* Set the color of the scrollbar thumb */
    border-radius: 6px; /* Set the border radius for a smoother appearance */
}

/* Customize the scrollbar track (background of the scrollbar) */
.RolesContainer::-webkit-scrollbar-track {
    background: #f1f1f1; /* Set the color of the scrollbar track */
}

/* Hide the scrollbar buttons (up and down arrows) */
.RolesContainer::-webkit-scrollbar-button {
    display: none;
}

/* Optional: Customize the scrollbar thumb on hover */
.RolesContainer::-webkit-scrollbar-thumb:hover {
    background: #555; /* Set the color when the thumb is hovered over */
}