 .PostItView {
    background-color: #fff594;
     margin-bottom: 15px;
     border-radius: 2px;
     padding: 15px;
 }

 .RoleHeader {
     margin-bottom: 5px;
     font-weight: 700;
     font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
     font-size: 14px;
     line-height: 1.42857143;
     color: #333;
 }